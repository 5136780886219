import React from 'react';
import AdminLayout from '../../Hoc/AdminLayout';

const Dashboard = () => {

return (
    <AdminLayout>
<div className="user_dashboard">
    <div>
        this is dashboard!
    </div>
</div>
</AdminLayout>
);


};

export default Dashboard;